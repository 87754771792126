<template>
  <card
    :content="content"
    :schema="getSchemaPreraiser"
    :form-data="getDataPreraiser"
    :editable="getEditablePreraiser"
    @update-form="updatePreraiser"
    @submitAction="addPreraiser"
  />
</template>
<script>
/* eslint-disable no-console */
import Card from "../components/Card";
import confPreraiser from "../conf/confPreraiser";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Preraiser",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confPreraiser.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("preraiser/loadPreraiser");
  },
  computed: {
    ...mapGetters("preraiser", [
      "getDataPreraiser",
      "getSchemaPreraiser",
      "getEditablePreraiser"
    ])
  },
  methods: {
    ...mapActions("preraiser", [
      "updatePreraiser",
      "addPreraiser",
      "loadPreraiser"
    ])
    /*checkSizes() {
     if (document.getElementById("content")) {
     return document.getElementById("content").clientWidth;
     } else if (document.getElementById("submitForm")) {
     return document.getElementById("submitForm").clientWidth;
     } else return window.screen.availWidth;
     }*/
  }

  /*mounted() {
   let hwidth = this.checkSizes();
   if (hwidth < 820) this.$store.state.rearer.schema.rStables.dense = true;
   }*/
};
</script>

<style scoped></style>
